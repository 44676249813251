#section_642716362 {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: rgb(255, 255, 255);
}
#section_642716362 .section-bg-overlay {
  background-color: rgba(255, 255, 255, 0.85);
}
#image_1138748791 {
  width: 100%;
  background-image: url(../../img3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}