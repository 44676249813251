#banner-154701534 {
  padding-top: 355px;
}
#banner-154701534 {
  background-image: url(./background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
#banner-154701534 .bg {
  background-position: 62% 60%;
}
@media (min-width: 550px) {
  #banner-154701534 {
    padding-top: 637px;
  }
}
#text-box-1138880014 .text {
  background-color: rgba(255, 255, 255, 0.94);
  font-size: 100%;
}
#text-box-1138880014 .text-inner {
  padding: 30px;
}