.icon {
  &__footer {
    background-image: url(../../arrow-up.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;

    &:hover {
      background-image: url(../../arrow-up-light.svg);
    }
  }
}