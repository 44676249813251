#section_1293148565 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(193, 193, 193);
}
@media (min-width: 550px) {
  #section_1293148565 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}