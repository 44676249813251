/* App.css */
.main-menu {
  position: fixed;
  top: 0;
  left: -300px; /* Initially hidden off-screen */
  width: 300px;
  height: 100%;
  background: white;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  transition: left 0.3s ease;
}

.main-menu.open {
  left: 0;
}

.main-menu ul {
  list-style-type: none;
  padding: 0;
}

.main-menu ul li {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}
