#section_1977332948 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(255, 255, 255);
}
#section_1977332948 .section-bg-overlay {
  background-color: rgba(255, 255, 255, 0.85);
}
#image_992208503 {
  width: 100%;
  background-image: url(../../img2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 550px) {
  #section_1977332948 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}