#section_1229553871 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(255, 255, 255);
}
#section_1229553871 .section-bg-overlay {
  background-color: rgba(255, 255, 255, 0.85);
}
#image_543 {
  width: 100%;
  background-image: url(../../img1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 550px) {
  #section_1229553871 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}